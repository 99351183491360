import { useState, useEffect } from "react";
import { IgrFinancialChart } from "igniteui-react-charts";
import { IgrFinancialChartModule } from "igniteui-react-charts";
import StocksHistory from "./StocksHistory";

IgrFinancialChartModule.register();

const DemoStock = () => {
  const [stocksData, setStocksData] = useState<any[]>([]);

  const getStocksData = async () => {
    StocksHistory.getMultipleStocks().then((stocks: any[]) => {
      setStocksData(stocks);
    });
  };

  useEffect(() => {
    getStocksData();
  }, []);

  return (
    <div style={{ padding: "0 1em" }}>
      <h1>Ignite UI React</h1>
      <div className="container" style={{ height: "calc(100vh - 25px)" }}>
        <IgrFinancialChart
          width="100%"
          height="100%"
          chartType="Line"
          thickness={2}
          chartTitle="Stock Changes"
          yAxisMode="PercentChange"
          yAxisTitle="Percent Changed"
          dataSource={stocksData}
        />
      </div>
    </div>
  );
};

export default DemoStock;
