export const fakeData = {
  array: [
    {
      date: "2024-11-08T00:00:00",
      open: 1085,
      high: 1090,
      low: 1080,
      close: 1090,
      volume: 37099479,
      SMA5: 1061,
      SMA20: 1056.25,
      SMA60: 1014.9996658325196,
      EMA5: 1065.8568817383275,
      EMA20: 1046.3593696746589,
      EMA40: 1022.384917128947,
      EMA12: 1055.4568755678754,
      EMA26: 1038.8094536848162,
      RSI7: 81.25,
      RSI14: 51.85185185185185,
      RSI21: 62.96296296296296,
      MACD: 16.647421883059224,
      signal_line: 17.499420133031443,
      middle_band: 1056.25,
      upper_band: 1093.3664596770027,
      lower_band: 1019.1335403229974,
      percent_b: 0.9546500433190755,
      BBW: 0.07027968696237186,
      ATR: 25.642857142857142,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1065,
      day_trading_signal: "今日此股票為好的當沖標的",
    },
    {
      date: "2024-11-11T00:00:00",
      open: 1080,
      high: 1090,
      low: 1070,
      close: 1085,
      volume: 34690205,
      SMA5: 1070,
      SMA20: 1058.25,
      SMA60: 1019.625,
      EMA5: 1072.237921158885,
      EMA20: 1050.0394297056437,
      EMA40: 1025.4393114153397,
      EMA12: 1060.001971634356,
      EMA26: 1042.230975634089,
      RSI7: 86.66666666666667,
      RSI14: 53.846153846153854,
      RSI21: 58,
      MACD: 17.770996000267132,
      signal_line: 17.55373530647858,
      middle_band: 1058.25,
      upper_band: 1097.085008622943,
      lower_band: 1019.414991377057,
      percent_b: 0.844405743020701,
      BBW: 0.07339477178916692,
      ATR: 25.285714285714285,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1090,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價",
    },
    {
      date: "2024-11-12T00:00:00",
      open: 1060,
      high: 1065,
      low: 1050,
      close: 1050,
      volume: 51099848,
      SMA5: 1070,
      SMA20: 1057.25,
      SMA60: 1023.45,
      EMA5: 1064.82528077259,
      EMA20: 1050.0356744955825,
      EMA40: 1026.6373937853232,
      EMA12: 1058.463206767532,
      EMA26: 1042.8064589204528,
      RSI7: 61.904761904761905,
      RSI14: 46.666666666666664,
      RSI21: 50.87719298245614,
      MACD: 15.65674784707926,
      signal_line: 17.174337814598715,
      middle_band: 1057.25,
      upper_band: 1095.8402905705955,
      lower_band: 1018.6597094294045,
      percent_b: 0.40606445438993066,
      BBW: 0.07300125906000562,
      ATR: 26.357142857142858,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1085,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價",
    },
    {
      date: "2024-11-13T00:00:00",
      open: 1045,
      high: 1050,
      low: 1035,
      close: 1035,
      volume: 42331232,
      SMA5: 1065,
      SMA20: 1056.75,
      SMA60: 1025.825,
      EMA5: 1054.8835205150601,
      EMA20: 1048.6037054960032,
      EMA40: 1027.0453257957952,
      EMA12: 1054.8534826494501,
      EMA26: 1042.2282027041229,
      RSI7: 47.61904761904762,
      RSI14: 42.42424242424243,
      RSI21: 43.63636363636363,
      MACD: 12.625279945327293,
      signal_line: 16.26452624074443,
      middle_band: 1056.75,
      upper_band: 1096.2568281907243,
      lower_band: 1017.2431718092757,
      percent_b: 0.22473112881906016,
      BBW: 0.0747704342384183,
      ATR: 26,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1050,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價",
    },
    {
      date: "2024-11-14T00:00:00",
      open: 1030,
      high: 1040,
      low: 1025,
      close: 1035,
      volume: 44627940,
      SMA5: 1059,
      SMA20: 1056.75,
      SMA60: 1028.025,
      EMA5: 1048.2556803433736,
      EMA20: 1047.308114496384,
      EMA40: 1027.4333586838052,
      EMA12: 1051.7991007033809,
      EMA26: 1041.6927802815953,
      RSI7: 42.10526315789474,
      RSI14: 40.625,
      RSI21: 47.99999999999999,
      MACD: 10.10632042178554,
      signal_line: 15.032885076952654,
      middle_band: 1056.75,
      upper_band: 1096.2568281907243,
      lower_band: 1017.2431718092757,
      percent_b: 0.22473112881906016,
      BBW: 0.0747704342384183,
      ATR: 26.357142857142858,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1035,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價",
    },
    {
      date: "2024-11-15T00:00:00",
      open: 1040,
      high: 1045,
      low: 1030,
      close: 1035,
      volume: 34791611,
      SMA5: 1048,
      SMA20: 1054.25,
      SMA60: 1030.225,
      EMA5: 1043.8371202289159,
      EMA20: 1046.135913115776,
      EMA40: 1027.8024631382536,
      EMA12: 1049.2146236720914,
      EMA26: 1041.197018779255,
      RSI7: 35.294117647058826,
      RSI14: 44.827586206896555,
      RSI21: 50,
      MACD: 8.017604892836516,
      signal_line: 13.629829040129428,
      middle_band: 1054.25,
      upper_band: 1092.5390667369436,
      lower_band: 1015.9609332630564,
      percent_b: 0.24862275787168192,
      BBW: 0.07263754657233787,
      ATR: 25.285714285714285,
      EMA_cycle: "牛市結束期",
      EMA_cycle_instructions:
        "ema20 & 40 左下右上, ema5 緩慢下滑, 行情仍強, 賣出條件為 ema5 持續下跌, ema20 停止上漲",
      close_yesterday: 1035,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價, RSI7 值小於 40",
    },
    {
      date: "2024-11-18T00:00:00",
      open: 1030,
      high: 1035,
      low: 1020,
      close: 1025,
      volume: 31815076,
      SMA5: 1036,
      SMA20: 1051.25,
      SMA60: 1032.325,
      EMA5: 1037.5580801526107,
      EMA20: 1044.1229690095117,
      EMA40: 1027.6657576193143,
      EMA12: 1045.4892969533082,
      EMA26: 1039.9972396104213,
      RSI7: 27.77777777777777,
      RSI14: 41.935483870967744,
      RSI21: 35,
      MACD: 5.4920573428869375,
      signal_line: 12.00227470068093,
      middle_band: 1051.25,
      upper_band: 1088.7894529305227,
      lower_band: 1013.7105470694773,
      percent_b: 0.15036784035474587,
      BBW: 0.07141869760860434,
      ATR: 24.214285714285715,
      EMA_cycle: "牛市結束期",
      EMA_cycle_instructions:
        "ema20 & 40 左下右上, ema5 緩慢下滑, 行情仍強, 賣出條件為 ema5 持續下跌, ema20 停止上漲",
      close_yesterday: 1035,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價, RSI7 值小於 40",
    },
    {
      date: "2024-11-19T00:00:00",
      open: 1030,
      high: 1045,
      low: 1025,
      close: 1040,
      volume: 24349599,
      SMA5: 1034,
      SMA20: 1049.5,
      SMA60: 1034.325,
      EMA5: 1038.372053435074,
      EMA20: 1043.7303052943203,
      EMA40: 1028.2674279793475,
      EMA12: 1044.6447897297223,
      EMA26: 1039.9974440837234,
      RSI7: 18.75,
      RSI14: 50,
      RSI21: 39.53488372093023,
      MACD: 4.647345645998939,
      signal_line: 10.531288889744532,
      middle_band: 1049.5,
      upper_band: 1085.6138550465455,
      lower_band: 1013.3861449534544,
      percent_b: 0.36847153277106787,
      BBW: 0.0688210672635456,
      ATR: 23.5,
      EMA_cycle: "牛市結束期",
      EMA_cycle_instructions:
        "ema20 & 40 左下右上, ema5 緩慢下滑, 行情仍強, 賣出條件為 ema5 持續下跌, ema20 停止上漲",
      close_yesterday: 1025,
      day_trading_signal: "今日此股票並非好的當沖標的, 原因: RSI7 值小於 40",
    },
    {
      date: "2024-11-20T00:00:00",
      open: 1025,
      high: 1025,
      low: 1025,
      close: 1025,
      volume: 156000,
      SMA5: 1032,
      SMA20: 1047.75,
      SMA60: 1035.625,
      EMA5: 1033.9147022900493,
      EMA20: 1041.9464666948613,
      EMA40: 1028.1080412486476,
      EMA12: 1041.6225143866882,
      EMA26: 1038.8865222997438,
      RSI7: 16.666666666666657,
      RSI14: 48.48484848484849,
      RSI21: 38.63636363636363,
      MACD: 2.7359920869444068,
      signal_line: 8.972229529184506,
      middle_band: 1047.75,
      upper_band: 1085.0926523457777,
      lower_band: 1010.4073476542222,
      percent_b: 0.19538853601848852,
      BBW: 0.07128160791367733,
      ATR: 22.785714285714285,
      EMA_cycle: "牛市結束期",
      EMA_cycle_instructions:
        "ema20 & 40 左下右上, ema5 緩慢下滑, 行情仍強, 賣出條件為 ema5 持續下跌, ema20 停止上漲",
      close_yesterday: 1040,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價, RSI7 值小於 40",
    },
    {
      date: "2024-11-21T00:00:00",
      open: 1020,
      high: 1025,
      low: 1010,
      close: 1010,
      volume: 41429227,
      SMA5: 1027,
      SMA20: 1045.25,
      SMA60: 1036.45,
      EMA5: 1025.9431348600328,
      EMA20: 1038.9039460572556,
      EMA40: 1027.2247221633477,
      EMA12: 1036.7575121733516,
      EMA26: 1036.74677990717,
      RSI7: 21.42857142857143,
      RSI14: 45.714285714285715,
      RSI21: 38.63636363636363,
      MACD: 0.010732266181548766,
      signal_line: 7.179930076583915,
      middle_band: 1045.25,
      upper_band: 1085.704652975591,
      lower_band: 1004.7953470244091,
      percent_b: 0.06432700063860639,
      BBW: 0.0774066548205519,
      ATR: 21.428571428571427,
      EMA_cycle: "熊市入口期",
      EMA_cycle_instructions:
        "全數出清穩定上升期布局的多頭部位, 考慮提早佈局建立空頭部位",
      close_yesterday: 1025,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價, RSI7 值小於 40",
    },
    {
      date: "2024-11-22T00:00:00",
      open: 1035,
      high: 1050,
      low: 1035,
      close: 1040,
      volume: 25896480,
      SMA5: 1028,
      SMA20: 1044,
      SMA60: 1037.775,
      EMA5: 1030.6287565733553,
      EMA20: 1039.0083321470406,
      EMA40: 1027.8479064480623,
      EMA12: 1037.2563564543743,
      EMA26: 1036.9877591733057,
      RSI7: 52.94117647058823,
      RSI14: 50,
      RSI21: 46,
      MACD: 0.2685972810686508,
      signal_line: 5.797663517480863,
      middle_band: 1044,
      upper_band: 1083.4168011199965,
      lower_band: 1004.5831988800034,
      percent_b: 0.4492602153606689,
      BBW: 0.07551111325669844,
      ATR: 22.857142857142858,
      EMA_cycle: "牛市結束期",
      EMA_cycle_instructions:
        "ema20 & 40 左下右上, ema5 緩慢下滑, 行情仍強, 賣出條件為 ema5 持續下跌, ema20 停止上漲",
      close_yesterday: 1010,
      day_trading_signal: "今日此股票為好的當沖標的",
    },
    {
      date: "2024-11-25T00:00:00",
      open: 1040.9999561494994,
      high: 1045.9808171837076,
      low: 1026.057373046875,
      close: 1026.057373046875,
      volume: 49566674,
      SMA5: 1028.211474609375,
      SMA20: 1042.8028686523437,
      SMA60: 1038.3014343261718,
      EMA5: 1029.1049620645285,
      EMA20: 1037.7749074708345,
      EMA40: 1027.7605633553214,
      EMA12: 1035.5334359301437,
      EMA26: 1036.1781009417182,
      RSI7: 45.480902807764714,
      RSI14: 43.82739438738452,
      RSI21: 42.48045263706725,
      MACD: -0.6446650115744887,
      signal_line: 4.509197811669793,
      middle_band: 1042.8028686523437,
      upper_band: 1082.9008418068997,
      lower_band: 1002.7048954977877,
      percent_b: 0.29119274257424604,
      BBW: 0.07690422487306016,
      ATR: 22.49453172405947,
      EMA_cycle: "牛市結束期",
      EMA_cycle_instructions:
        "ema20 & 40 左下右上, ema5 緩慢下滑, 行情仍強, 賣出條件為 ema5 持續下跌, ema20 停止上漲",
      close_yesterday: 1040,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價",
    },
    {
      date: "2024-11-26T00:00:00",
      open: 1011.114833416325,
      high: 1016.0956946646813,
      low: 1006.1339721679688,
      close: 1006.1339721679688,
      volume: 27312402,
      SMA5: 1021.4382690429687,
      SMA20: 1040.609567260742,
      SMA60: 1038.0797836303711,
      EMA5: 1021.447965432342,
      EMA20: 1034.7614850610378,
      EMA40: 1026.7056076876456,
      EMA12: 1031.0104415051937,
      EMA26: 1033.9526099214404,
      RSI7: 37.85774692798617,
      RSI14: 36.78886609876649,
      RSI21: 41.687821999587804,
      MACD: -2.942168416246659,
      signal_line: 3.0189245660865027,
      middle_band: 1040.609567260742,
      upper_band: 1083.7345343669992,
      lower_band: 997.4846001544851,
      percent_b: 0.10028265056031463,
      BBW: 0.08288404885566732,
      ATR: 21.06048892969563,
      EMA_cycle: "熊市入口期",
      EMA_cycle_instructions:
        "全數出清穩定上升期布局的多頭部位, 考慮提早佈局建立空頭部位",
      close_yesterday: 1026.057373046875,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價, RSI7 值小於 40",
    },
    {
      date: "2024-11-27T00:00:00",
      open: 1006.1339636230468,
      high: 1016.0956860351563,
      low: 996.1722412109375,
      close: 996.1722412109375,
      volume: 31276090,
      SMA5: 1015.6727172851563,
      SMA20: 1038.418179321289,
      SMA60: 1037.9840896606445,
      EMA5: 1013.0227240252073,
      EMA20: 1031.0863189800757,
      EMA40: 1025.2161751765866,
      EMA12: 1025.6507183830004,
      EMA26: 1031.1540640910328,
      RSI7: 37.869939194832334,
      RSI14: 33.52044785899713,
      RSI21: 40.17123774684078,
      MACD: -5.503345708032384,
      signal_line: 1.3144705112627253,
      middle_band: 1038.418179321289,
      upper_band: 1085.906979097297,
      lower_band: 990.9293795452811,
      percent_b: 0.055201033616195626,
      BBW: 0.09146372958733567,
      ATR: 20.697877845711258,
      EMA_cycle: "熊市入口期",
      EMA_cycle_instructions:
        "全數出清穩定上升期布局的多頭部位, 考慮提早佈局建立空頭部位",
      close_yesterday: 1006.1339721679688,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價, RSI7 值小於 40",
    },
    {
      date: "2024-11-28T00:00:00",
      open: 1001.1531372070312,
      high: 1001.1531372070312,
      low: 988.2028976212687,
      close: 1001.1531372070312,
      volume: 33689956,
      SMA5: 1013.9033447265625,
      SMA20: 1036.9758361816407,
      SMA60: 1039.0879180908203,
      EMA5: 1009.0661950858154,
      EMA20: 1028.2355397635954,
      EMA40: 1024.0423684463644,
      EMA12: 1021.8818597405435,
      EMA26: 1028.9317732107363,
      RSI7: 32.149001442177436,
      RSI14: 26.471718710652624,
      RSI21: 42.774253711433346,
      MACD: -7.0499134701927915,
      signal_line: -0.35840628502837824,
      middle_band: 1036.9758361816407,
      upper_band: 1087.2138895734242,
      lower_band: 986.7377827898573,
      percent_b: 0.14347047152439632,
      BBW: 0.09689339257271483,
      ATR: 19.837180673265728,
      EMA_cycle: "熊市入口期",
      EMA_cycle_instructions:
        "全數出清穩定上升期布局的多頭部位, 考慮提早佈局建立空頭部位",
      close_yesterday: 996.1722412109375,
      day_trading_signal: "今日此股票並非好的當沖標的, 原因: RSI7 值小於 40",
    },
    {
      date: "2024-11-29T00:00:00",
      open: 991.1913887851209,
      high: 1006.1339725356504,
      low: 990.1952165350856,
      close: 992.1875610351562,
      volume: 40094983,
      SMA5: 1004.3408569335937,
      SMA20: 1035.3352142333983,
      SMA60: 1039.5926071166991,
      EMA5: 1003.4399837355958,
      EMA20: 1024.8023989323156,
      EMA40: 1022.4884754019151,
      EMA12: 1017.3135060935608,
      EMA26: 1026.2099797162489,
      RSI7: 34.03664096568998,
      RSI14: 25.92716658651038,
      RSI21: 42.939492566237384,
      MACD: -8.896473622688063,
      signal_line: -2.0660197525603152,
      middle_band: 1035.3352142333983,
      upper_band: 1089.2300058412122,
      lower_band: 981.4404226255845,
      percent_b: 0.0997047960383395,
      BBW: 0.10411080559588547,
      ATR: 19.547091816163213,
      EMA_cycle: "熊市入口期",
      EMA_cycle_instructions:
        "全數出清穩定上升期布局的多頭部位, 考慮提早佈局建立空頭部位",
      close_yesterday: 1001.1531372070312,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價, RSI7 值小於 40",
    },
    {
      date: "2024-12-02T00:00:00",
      open: 1016.0957455842391,
      high: 1036.019191576087,
      low: 1011.1148840862771,
      close: 1031.038330078125,
      volume: 31168404,
      SMA5: 1005.3370483398437,
      SMA20: 1034.8871307373047,
      SMA60: 1040.9435653686523,
      EMA5: 1012.6394325164389,
      EMA20: 1025.3962971366784,
      EMA40: 1022.9055414836814,
      EMA12: 1019.4250174758015,
      EMA26: 1026.5676352986102,
      RSI7: 58.3073366547384,
      RSI14: 45.17821489589956,
      RSI21: 51.00096644477828,
      MACD: -7.142617822808688,
      signal_line: -3.08133936660999,
      middle_band: 1034.8871307373047,
      upper_band: 1088.7676380659093,
      lower_band: 981.0066234087001,
      percent_b: 0.46428392335184643,
      BBW: 0.1041282778156058,
      ATR: 20.17792256908684,
      EMA_cycle: "熊市入口期",
      EMA_cycle_instructions:
        "全數出清穩定上升期布局的多頭部位, 考慮提早佈局建立空頭部位",
      close_yesterday: 992.1875610351562,
      day_trading_signal: "今日此股票為好的當沖標的",
    },
    {
      date: "2024-12-03T00:00:00",
      open: 1055.9425309167652,
      high: 1060.9233919116557,
      low: 1050.961669921875,
      close: 1050.961669921875,
      volume: 43414538,
      SMA5: 1014.302587890625,
      SMA20: 1034.9352142333985,
      SMA60: 1042.0926071166991,
      EMA5: 1025.4135116515845,
      EMA20: 1027.8310945447924,
      EMA40: 1024.2741331148127,
      EMA12: 1024.2768101598128,
      EMA26: 1028.3746008262594,
      RSI7: 54.7026280840082,
      RSI14: 53.95976219259589,
      RSI21: 51.78791865704807,
      MACD: -4.097790666446599,
      signal_line: -3.284629626577312,
      middle_band: 1034.9352142333985,
      upper_band: 1088.8741930786691,
      lower_band: 980.9962353881277,
      percent_b: 0.6485609853168527,
      BBW: 0.10423643548591513,
      ATR: 21.24114127148189,
      EMA_cycle: "牛市結束期",
      EMA_cycle_instructions:
        "ema20 & 40 左下右上, ema5 緩慢下滑, 行情仍強, 賣出條件為 ema5 持續下跌, ema20 停止上漲",
      close_yesterday: 1031.038330078125,
      day_trading_signal: "今日此股票為好的當沖標的",
    },
    {
      date: "2024-12-04T00:00:00",
      open: 1060.9234356746495,
      high: 1065.904296875,
      low: 1050.9617132739486,
      close: 1065.904296875,
      volume: 33466290,
      SMA5: 1028.2489990234376,
      SMA20: 1035.2304290771485,
      SMA60: 1043.4902145385743,
      EMA5: 1038.9104400593897,
      EMA20: 1031.457113814336,
      EMA40: 1026.3048728104316,
      EMA12: 1030.6810388852261,
      EMA26: 1031.1545783113513,
      RSI7: 66.9491648631921,
      RSI14: 57.13754881606591,
      RSI21: 52.552930673808476,
      MACD: -0.4735394261251713,
      signal_line: -2.722411586486884,
      middle_band: 1035.2304290771485,
      upper_band: 1089.807870711066,
      lower_band: 980.6529874432309,
      percent_b: 0.7810123274337307,
      BBW: 0.1054401804679763,
      ATR: 21.237043196705105,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1050.961669921875,
      day_trading_signal: "今日此股票為好的當沖標的",
    },
    {
      date: "2024-12-05T00:00:00",
      open: 1065.9042707576307,
      high: 1075.865992914244,
      low: 1065.9042707576307,
      close: 1070.8851318359375,
      volume: 31425359,
      SMA5: 1042.1953979492187,
      SMA20: 1035.5246856689453,
      SMA60: 1044.7623428344727,
      EMA5: 1049.5686706515723,
      EMA20: 1035.2121631497266,
      EMA40: 1028.4795195921636,
      EMA12: 1036.8662839545664,
      EMA26: 1034.0975822761354,
      RSI7: 81.55337033703668,
      RSI14: 58.101512596890146,
      RSI21: 51.775878200458294,
      MACD: 2.7687016784309435,
      signal_line: -1.6241889335033186,
      middle_band: 1035.5246856689453,
      upper_band: 1090.8364530088315,
      lower_band: 980.212918329059,
      percent_b: 0.8196466852135206,
      BBW: 0.10682848628404269,
      ATR: 20.877166207891776,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1065.904296875,
      day_trading_signal: "今日此股票為好的當沖標的",
    },
    {
      date: "2024-12-06T00:00:00",
      open: 1070.885184561143,
      high: 1075.866045884683,
      low: 1055.9426005905223,
      close: 1060.9234619140625,
      volume: 30765288,
      SMA5: 1055.942578125,
      SMA20: 1034.0708587646484,
      SMA60: 1045.1604293823243,
      EMA5: 1053.3536010724024,
      EMA20: 1037.6608582701397,
      EMA40: 1030.062150924939,
      EMA12: 1040.5673882560272,
      EMA26: 1036.0846844715375,
      RSI7: 81.55341884917661,
      RSI14: 58.11156995407344,
      RSI21: 49.34552023840741,
      MACD: 4.482703784489786,
      signal_line: -0.40281038990469786,
      middle_band: 1034.0708587646484,
      upper_band: 1084.6826440359864,
      lower_band: 983.4590734933103,
      percent_b: 0.7652801418232231,
      BBW: 0.09788842774624051,
      ATR: 21.2288408717604,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1070.8851318359375,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價",
    },
    {
      date: "2024-12-09T00:00:00",
      open: 1065.9042707576307,
      high: 1070.8851318359375,
      low: 1060.923409679324,
      close: 1070.8851318359375,
      volume: 22247131,
      SMA5: 1063.9119384765625,
      SMA20: 1033.3651153564454,
      SMA60: 1045.8075576782226,
      EMA5: 1059.1974446602476,
      EMA20: 1040.8250748002156,
      EMA40: 1032.0535158474268,
      EMA12: 1045.2316564990904,
      EMA26: 1038.6624953874189,
      RSI7: 82.40741318559003,
      RSI14: 57.13628125089904,
      RSI21: 46.77544171932511,
      MACD: 6.569161111671519,
      signal_line: 0.9915839104105457,
      middle_band: 1033.3651153564454,
      upper_band: 1081.3101005819585,
      lower_band: 985.4201301309321,
      percent_b: 0.8912819693552275,
      BBW: 0.09279389155492296,
      ATR: 20.511821025804213,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1060.9234619140625,
      day_trading_signal: "今日此股票為好的當沖標的",
    },
    {
      date: "2024-12-10T00:00:00",
      open: 1065.9043232376027,
      high: 1070.885184561143,
      low: 1055.9426005905223,
      close: 1060.9234619140625,
      volume: 28506584,
      SMA5: 1065.904296875,
      SMA20: 1033.9112884521485,
      SMA60: 1045.5806442260741,
      EMA5: 1059.7727837448526,
      EMA20: 1042.7392069062962,
      EMA40: 1033.4618058994577,
      EMA12: 1047.645780409086,
      EMA26: 1040.311455870874,
      RSI7: 81.65144009304015,
      RSI14: 58.49829765483544,
      RSI21: 46.0053104550343,
      MACD: 7.3343245382120585,
      signal_line: 2.2601320359708486,
      middle_band: 1033.9112884521485,
      upper_band: 1082.8918530535252,
      lower_band: 984.9307238507719,
      percent_b: 0.7757437943166823,
      BBW: 0.09474809908440919,
      ATR: 20.50771988084855,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1070.8851318359375,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價",
    },
    {
      date: "2024-12-10T00:00:00",
      open: 1065.9043232376027,
      high: 1070.885184561143,
      low: 1055.9426005905223,
      close: 1060.9234619140625,
      volume: 28506584,
      SMA5: 1064.9081298828125,
      SMA20: 1035.2074615478516,
      SMA60: 1045.9787307739257,
      EMA5: 1060.1563431345894,
      EMA20: 1044.4710407165596,
      EMA40: 1034.8013988757798,
      EMA12: 1049.688500640621,
      EMA26: 1041.8382711333325,
      RSI7: 71.42862144481421,
      RSI14: 62.96707293191715,
      RSI21: 52.05053173815706,
      MACD: 7.850229507288532,
      signal_line: 3.378151530234385,
      middle_band: 1035.2074615478516,
      upper_band: 1085.6592635491622,
      lower_band: 984.7556595465409,
      percent_b: 0.754857104663406,
      BBW: 0.09747186699345209,
      ATR: 20.50361873589289,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1060.9234619140625,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價",
    },
    {
      date: "2024-12-11T00:00:00",
      open: 1041,
      high: 1055.9425837320573,
      low: 1041,
      close: 1041,
      volume: 25264194,
      SMA5: 1058.931103515625,
      SMA20: 1035.5074615478516,
      SMA60: 1046.1287307739258,
      EMA5: 1053.7708954230598,
      EMA20: 1044.1404654102207,
      EMA40: 1035.103769662327,
      EMA12: 1048.3518082343717,
      EMA26: 1041.7761769753079,
      RSI7: 42.85716786522047,
      RSI14: 50.268412747508535,
      RSI21: 51.105867360713624,
      MACD: 6.575631259063812,
      signal_line: 4.0176474760002705,
      middle_band: 1035.5074615478516,
      upper_band: 1086.0253812575488,
      lower_band: 984.9895418381543,
      percent_b: 0.5543622786103573,
      BBW: 0.09757132919966464,
      ATR: 19.069580301183066,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1060.9234619140625,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價",
    },
    {
      date: "2024-12-11T00:00:00",
      open: 1041,
      high: 1055.9425837320573,
      low: 1041,
      close: 1041,
      volume: 25264194,
      SMA5: 1054.9464111328125,
      SMA20: 1035.8074615478515,
      SMA60: 1045.0287307739259,
      EMA5: 1049.5139302820398,
      EMA20: 1043.8413734663902,
      EMA40: 1035.3913906544087,
      EMA12: 1047.2207608136991,
      EMA26: 1041.7186823845443,
      RSI7: 27.27266650922168,
      RSI14: 54.33527649296284,
      RSI21: 51.105867360713624,
      MACD: 5.502078429154835,
      signal_line: 4.314533666631183,
      middle_band: 1035.8074615478515,
      upper_band: 1086.3839205349586,
      lower_band: 985.2310025607444,
      percent_b: 0.5513335507876516,
      BBW: 0.09765610089645144,
      ATR: 18.713804557984833,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1041,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價, RSI7 值小於 40",
    },
    {
      date: "2024-12-12T00:00:00",
      open: 1060,
      high: 1065,
      low: 1055,
      close: 1060,
      volume: 22791260,
      SMA5: 1052.769384765625,
      SMA20: 1037.5574615478515,
      SMA60: 1044.4037307739259,
      EMA5: 1053.0092868546933,
      EMA20: 1045.380290279115,
      EMA40: 1036.5918106224863,
      EMA12: 1049.1867976115916,
      EMA26: 1043.0728540597634,
      RSI7: 42.09026768781523,
      RSI14: 65.712237813656,
      RSI21: 54.30618307333631,
      MACD: 6.113943551828243,
      signal_line: 4.674415643670596,
      middle_band: 1037.5574615478515,
      upper_band: 1088.974485472668,
      lower_band: 986.1404376230349,
      percent_b: 0.7182403486145432,
      BBW: 0.09911166529150388,
      ATR: 19.004990209491528,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1041,
      day_trading_signal: "今日此股票為好的當沖標的",
    },
    {
      date: "2024-12-12T00:00:00",
      open: 1060,
      high: 1065,
      low: 1055,
      close: 1060,
      volume: 22791260,
      SMA5: 1052.5846923828126,
      SMA20: 1038.5574615478515,
      SMA60: 1044.0287307739259,
      EMA5: 1055.3395245697957,
      EMA20: 1046.7726435858658,
      EMA40: 1037.7336735189504,
      EMA12: 1050.8503672098082,
      EMA26: 1044.326716722003,
      RSI7: 49.215367796993405,
      RSI14: 69.76672798440065,
      RSI21: 56.243750152435304,
      MACD: 6.523650487805071,
      signal_line: 5.044262612497491,
      middle_band: 1038.5574615478515,
      upper_band: 1090.9433288609482,
      lower_band: 986.1715942347548,
      percent_b: 0.7046595728194401,
      BBW: 0.10088198150350108,
      ATR: 18.296172722047327,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1060,
      day_trading_signal:
        "今日此股票並非好的當沖標的, 原因: 今日收盤價未高於昨日收盤價",
    },
    {
      date: "2024-12-13T00:00:00",
      open: 1060,
      high: 1070,
      low: 1055,
      close: 1065,
      volume: 21043042,
      SMA5: 1053.4,
      SMA20: 1040.5574615478515,
      SMA60: 1044.1537307739259,
      EMA5: 1058.5596830465306,
      EMA20: 1048.5085822919739,
      EMA40: 1039.063738225343,
      EMA12: 1053.0272337929146,
      EMA26: 1045.8580710388917,
      RSI7: 44.53918767995614,
      RSI14: 69.77030493836634,
      RSI21: 54.624828827136184,
      MACD: 7.169162754022864,
      signal_line: 5.469242640802566,
      middle_band: 1040.5574615478515,
      upper_band: 1093.8110332558156,
      lower_band: 987.3038898398876,
      percent_b: 0.7294920102841954,
      BBW: 0.10235584996670567,
      ATR: 18.442584180207145,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1060,
      day_trading_signal: "今日此股票為好的當沖標的",
    },
    {
      date: "2024-12-13T00:00:00",
      open: "不適用",
      high: "不適用",
      low: "不適用",
      close: "不適用",
      volume: "不適用",
      SMA5: "不適用",
      SMA20: "不適用",
      SMA60: "不適用",
      EMA5: 1058.5596830465306,
      EMA20: 1048.5085822919739,
      EMA40: 1039.063738225343,
      EMA12: 1053.0272337929146,
      EMA26: 1045.8580710388917,
      RSI7: 54.64050180506418,
      RSI14: 73.87198493271791,
      RSI21: 57.83452623686435,
      MACD: 7.169162754022864,
      signal_line: 5.809226663446626,
      middle_band: "不適用",
      upper_band: "不適用",
      lower_band: "不適用",
      percent_b: "不適用",
      BBW: "不適用",
      ATR: 18.63518634787194,
      EMA_cycle: "穩定上升期",
      EMA_cycle_instructions:
        "三條移動平均線都左下右上, 買方優勢, 三線間隔越來越遠時, 進一步強攻",
      close_yesterday: 1065,
      day_trading_signal: "今日此股票為好的當沖標的",
    },
  ],
};
