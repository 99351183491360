type APIFunction = (params: {
  ticker: string;
}) => Promise<{ data: [any, any] }>;

type APICallParams = {
  loadingFn: React.Dispatch<React.SetStateAction<boolean>>;
  ApiFn: APIFunction;
  infoState: React.Dispatch<React.SetStateAction<any>>;
  ticker: string;
};

const treatData = (obj: any) => {
  const { year, season, ...rest } = obj;
  return rest;
};

const getInfoFn = ({ loadingFn, ApiFn, infoState, ticker }: APICallParams) => {
  loadingFn(true);

  ApiFn({ ticker })
    .then((res) => {
      let financeData = res.data;
      infoState(treatData(financeData));
    })
    .catch((err) => {
      console.error("Error during API call:", err);
    })
    .finally(() => {
      loadingFn(false);
    });
};

export default getInfoFn;
