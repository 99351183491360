import { useEffect, useState } from "react";
import FinanceCard from "../components/FinanceCard";

// keySets
import { financeDataOverviewTitle } from "../utility/common/common";
import { perShareDataTitle } from "../utility/common/common";
import { profitabilityDataTitle } from "../utility/common/common";
import { growthMomentumDataTitle } from "../utility/common/common";
import { operatingIndicatorsTitle } from "../utility/common/common";
import { financialResilienceDataTitle } from "../utility/common/common";
import { balanceSheetDataTitle } from "../utility/common/common";
import { Skeleton } from "antd";

import {
  getBalanceSheetData,
  getFinancialResilienceData,
  getGrowthMomentumData,
  getOperatingIndicatorsData,
  getPerShareData,
  getProfitabilityData,
} from "../server/Fundamental/vitals";
import getInfoFn from "../utility/fn/getInfoFn";

const Vitals = (props: any) => {
  const { year, ticker, season, overview, loading } = props;
  const [perShareData, setPerShareData] = useState<any>({});
  const [profitabilityData, setProfitabilityData] = useState<any>({});
  const [growthMomentumData, setGrowthMomentumData] = useState<any>({});
  const [operatingIndicatorsData, setOperatingIndicatorsData] = useState<any>(
    {}
  );
  const [financialResilienceData, setFinancialResilienceData] = useState<any>(
    {}
  );
  const [balanceSheetData, setBalanceSheetData] = useState<any>({});

  // loading part

  const [perShareLoading, setPerShareLoading] = useState<boolean>(false);
  const [profitabilityLoading, setProfitabilityLoading] =
    useState<boolean>(false);
  const [growthMomentumLoading, setGrowthMomentumLoading] =
    useState<boolean>(false);
  const [operatingIndicatorsLoading, setOperatingIndicatorsLoading] =
    useState<boolean>(false);
  const [financialResilienceLoading, setFinancialResilienceLoading] =
    useState<boolean>(false);
  const [balanceSheetLoading, setBalanceSheetLoading] =
    useState<boolean>(false);

  useEffect(() => {
    getInfoFn({
      loadingFn: setPerShareLoading,
      ApiFn: getPerShareData,
      infoState: setPerShareData,
      ticker,
    });
    getInfoFn({
      loadingFn: setProfitabilityLoading,
      ApiFn: getProfitabilityData,
      infoState: setProfitabilityData,
      ticker,
    });
    getInfoFn({
      loadingFn: setGrowthMomentumLoading,
      ApiFn: getGrowthMomentumData,
      infoState: setGrowthMomentumData,
      ticker,
    });
    getInfoFn({
      loadingFn: setOperatingIndicatorsLoading,
      ApiFn: getOperatingIndicatorsData,
      infoState: setOperatingIndicatorsData,
      ticker,
    });
    getInfoFn({
      loadingFn: setFinancialResilienceLoading,
      ApiFn: getFinancialResilienceData,
      infoState: setFinancialResilienceData,
      ticker,
    });
    getInfoFn({
      loadingFn: setBalanceSheetLoading,
      ApiFn: getBalanceSheetData,
      infoState: setBalanceSheetData,
      ticker,
    });
  }, []);
  return (
    <>
      <h2>財務概況</h2>
      <div className="finance-card-container">
        {loading ? (
          <Skeleton paragraph={true} active />
        ) : (
          Object.entries(overview).map((item: Object, idx: number) => {
            return (
              <FinanceCard
                key={idx}
                info={item}
                year={year}
                season={season}
                keySet={financeDataOverviewTitle}
                treat={true} // 這個會動用 LocalString，自動幫數據加應對的小數點
                unit={" 千元"}
              />
            );
          })
        )}
      </div>
      <h2>每股財務狀況</h2>
      <div className="finance-card-container">
        {perShareLoading ? (
          <Skeleton paragraph={true} active />
        ) : (
          Object.entries(perShareData).map((item: Object, idx: number) => {
            return (
              <FinanceCard
                key={idx}
                info={item}
                year={year}
                season={season}
                keySet={perShareDataTitle}
                treat={false}
              />
            );
          })
        )}
      </div>

      <h2>獲利能力</h2>
      <div className="finance-card-container">
        {profitabilityLoading ? (
          <Skeleton paragraph={true} active />
        ) : (
          Object.entries(profitabilityData).map((item: Object, idx: number) => {
            return (
              <FinanceCard
                key={idx}
                info={item}
                year={year}
                season={season}
                keySet={profitabilityDataTitle}
                treat={false}
              />
            );
          })
        )}
      </div>

      <h2>成長動能</h2>
      <div className="finance-card-container">
        {growthMomentumLoading ? (
          <Skeleton paragraph={true} active />
        ) : (
          Object.entries(growthMomentumData).map(
            (item: Object, idx: number) => {
              return (
                <FinanceCard
                  key={idx}
                  info={item}
                  year={year}
                  season={season}
                  keySet={growthMomentumDataTitle}
                  treat={false}
                />
              );
            }
          )
        )}
      </div>

      <h2>營運指標</h2>
      <div className="finance-card-container">
        {operatingIndicatorsLoading ? (
          <Skeleton paragraph={true} active />
        ) : (
          Object.entries(operatingIndicatorsData).map(
            (item: Object, idx: number) => {
              return (
                <FinanceCard
                  key={idx}
                  info={item}
                  year={year}
                  season={season}
                  keySet={operatingIndicatorsTitle}
                  treat={false}
                />
              );
            }
          )
        )}
      </div>

      <h2>財務韌性</h2>
      <div className="finance-card-container">
        {financialResilienceLoading ? (
          <Skeleton paragraph={true} active />
        ) : (
          Object.entries(financialResilienceData).map(
            (item: Object, idx: number) => {
              return (
                <FinanceCard
                  key={idx}
                  info={item}
                  year={year}
                  season={season}
                  keySet={financialResilienceDataTitle}
                  treat={false}
                />
              );
            }
          )
        )}
      </div>

      <h2>資產負債表</h2>
      <div className="finance-card-container">
        {balanceSheetLoading ? (
          <Skeleton paragraph={true} active />
        ) : (
          Object.entries(balanceSheetData).map((item: Object, idx: number) => {
            return (
              <FinanceCard
                key={idx}
                info={item}
                year={year}
                season={season}
                keySet={balanceSheetDataTitle}
                treat={true}
                unit={" 千元"}
              />
            );
          })
        )}
      </div>
    </>
  );
};

export default Vitals;
