import { useEffect, useState } from "react";
import {
  getBalanceSheetFullTableData,
  getBalanceSheetTotalAssetData,
  getBalanceSheetCurrentAssetData,
  getBalanceSheetNonCurrentAssetData,
  getBalanceSheetCurrentDebtData,
  getBalanceSheetNonCurrentDebtData,
  getBalanceSheetEquityData,
} from "../server/Fundamental/balanceSheet";

import BalanceSheetFullTable from "../components/BalanceSheet/BalanceSheetFullTable";
import BalanceSheetTotalAssetTable from "../components/BalanceSheet/BalanceSheetTotalAssetTable";
import BalanceSheetCurrentAssetTable from "../components/BalanceSheet/BalanceSheetCurrentAssetTable";
import BalanceSheetNonCurrentAssetTable from "../components/BalanceSheet/BalanceSheetNonCurrentAssetTable";
import BalanceSheetCurrentDebtTable from "../components/BalanceSheet/BalanceSheetCurrentDebtTable";
import BalanceSheetNonCurrentDebtTable from "../components/BalanceSheet/BalanceSheetNonCurrentDebtTable";
import BalanceSheetEquityTable from "../components/BalanceSheet/BalanceSheetEquityTable";

interface BalanceSheetTableDataType {
  key?: React.Key;
  title?: string | number;
  "2024Q3_value": string | number;
  "2024Q3_percentage": string | number;
  "2023Q3_value": string | number;
  "2023Q3_percentage": string | number;
  "2022Q3_value": string | number;
  "2022Q3_percentage": string | number;
  "2021Q3_value": string | number;
  "2021Q3_percentage": string | number;
  "2020Q3_value": string | number;
  "2020Q3_percentage": string | number;
  "2019Q3_value": string | number;
  "2019Q3_percentage": string | number;
  "2018Q3_value": string | number;
  "2018Q3_percentage": string | number;
}

const BalanceSheet = (props: any) => {
  const { ticker, companyTitle } = props;
  const [balanceSheetFullData, setbalanceSheetFullData] = useState<
    BalanceSheetTableDataType[]
  >([]);
  const [balanceSheetTotalAssetInfo, setBalanceSheetTotalAssetInfo] =
    useState<any>({});
  const [balanceSheetTotalAssetTableData, setBalanceSheetTotalAssetTableData] =
    useState<BalanceSheetTableDataType[]>([]);
  const [balanceSheetCurrentAssetInfo, setBalanceSheetCurrentAssetInfo] =
    useState<any>({});
  const [
    balanceSheetCurrentAssetTableData,
    setBalanceSheetCurrentAssetTableData,
  ] = useState<BalanceSheetTableDataType[]>([]);
  const [balanceSheetNonCurrentAssetInfo, setBalanceSheetNonCurrentAssetInfo] =
    useState<any>({});
  const [
    balanceSheetNonCurrentAssetTableData,
    setBalanceSheetNonCurrentAssetTableData,
  ] = useState<BalanceSheetTableDataType[]>([]);
  const [balanceSheetCurrentDebtInfo, setBalanceSheetCurrentDebtInfo] =
    useState<any>({});
  const [
    balanceSheetCurrentDebtTableData,
    setBalanceSheetCurrentDebtTableData,
  ] = useState<BalanceSheetTableDataType[]>([]);
  const [balanceSheetNonCurrentDebtInfo, setBalanceSheetNonCurrentDebtInfo] =
    useState<any>({});
  const [
    balanceSheetNonCurrentDebtTableData,
    setBalanceSheetNonCurrentDebtTableData,
  ] = useState<BalanceSheetTableDataType[]>([]);
  const [balanceSheetEquityInfo, setBalanceSheetEquityInfo] = useState<any>({});
  const [balanceSheetEquityTableData, setBalanceSheetEquityTableData] =
    useState<BalanceSheetTableDataType[]>([]);

  const [balanceSheetFullLoading, setBalanceSheetFullLoading] =
    useState<boolean>(false);
  const [
    balanceSheetTotalAssetTableLoading,
    setBalanceSheetTotalAssetTableLoading,
  ] = useState<boolean>(false);
  const [
    balanceSheetCurrentAssetTableLoading,
    setBalanceSheetCurrentAssetTableLoading,
  ] = useState<boolean>(false);
  const [
    balanceSheetNonCurrentAssetTableLoading,
    setBalanceSheetNonCurrentAssetTableLoading,
  ] = useState<boolean>(false);
  const [
    balanceSheetCurrentDebtTableLoading,
    setBalanceSheetCurrentDebtTableLoading,
  ] = useState<boolean>(false);
  const [
    balanceSheetNonCurrentDebtTableLoading,
    setBalanceSheetNonCurrentDebtTableLoading,
  ] = useState<boolean>(false);
  const [balanceSheetEquityTableLoading, setBalanceSheetEquityTableLoading] =
    useState<boolean>(false);

  const getbalanceSheetFullData = () => {
    setBalanceSheetFullLoading(true);
    getBalanceSheetFullTableData({ ticker })
      .then((res) => {
        const origArr = res.data;
        const treatedArr = origArr.map(
          (item: BalanceSheetTableDataType, idx: number) => ({
            ...item,
            key: idx,
          })
        );
        setbalanceSheetFullData(treatedArr);
        setBalanceSheetFullLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setBalanceSheetFullLoading(false);
      });
  };

  const getBalanceSheetTotalAssetTableData = () => {
    setBalanceSheetTotalAssetTableLoading(true);
    getBalanceSheetTotalAssetData({ ticker })
      .then((res) => {
        const origArr = res.data.array;
        const treatedArr = origArr.map((item: any, idx: number) => ({
          ...item,
          key: idx,
        }));
        setBalanceSheetTotalAssetInfo(res.data.content);
        setBalanceSheetTotalAssetTableData(treatedArr);
        setBalanceSheetTotalAssetTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setBalanceSheetTotalAssetTableLoading(false);
      });
  };

  const getBalanceSheetCurrentAssetTableData = () => {
    setBalanceSheetCurrentAssetTableLoading(true);
    getBalanceSheetCurrentAssetData({ ticker })
      .then((res) => {
        const origArr = res.data.array;
        const treatedArr = origArr.map((item: any, idx: number) => ({
          ...item,
          key: idx,
        }));
        setBalanceSheetCurrentAssetInfo(res.data.content);
        setBalanceSheetCurrentAssetTableData(treatedArr);
        setBalanceSheetCurrentAssetTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setBalanceSheetCurrentAssetTableLoading(false);
      });
  };

  const getBalanceSheetNonCurrentAssetTableData = () => {
    setBalanceSheetNonCurrentAssetTableLoading(true);
    getBalanceSheetNonCurrentAssetData({ ticker })
      .then((res) => {
        const origArr = res.data.array;
        const treatedArr = origArr.map((item: any, idx: number) => ({
          ...item,
          key: idx,
        }));
        setBalanceSheetNonCurrentAssetInfo(res.data.content);
        setBalanceSheetNonCurrentAssetTableData(treatedArr);
        setBalanceSheetNonCurrentAssetTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setBalanceSheetNonCurrentAssetTableLoading(false);
      });
  };

  const getBalanceSheetCurrentDebtTableData = () => {
    setBalanceSheetCurrentDebtTableLoading(true);
    getBalanceSheetCurrentDebtData({ ticker })
      .then((res) => {
        const origArr = res.data.array;
        const treatedArr = origArr.map((item: any, idx: number) => ({
          ...item,
          key: idx,
        }));
        setBalanceSheetCurrentDebtInfo(res.data.content);
        setBalanceSheetCurrentDebtTableData(treatedArr);
        setBalanceSheetCurrentDebtTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setBalanceSheetCurrentDebtTableLoading(false);
      });
  };

  const getBalanceSheetNonCurrentDebtTableData = () => {
    setBalanceSheetNonCurrentDebtTableLoading(true);
    getBalanceSheetNonCurrentDebtData({ ticker })
      .then((res) => {
        const origArr = res.data.array;
        const treatedArr = origArr.map((item: any, idx: number) => ({
          ...item,
          key: idx,
        }));
        setBalanceSheetNonCurrentDebtInfo(res.data.content);
        setBalanceSheetNonCurrentDebtTableData(treatedArr);
        setBalanceSheetNonCurrentDebtTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setBalanceSheetNonCurrentDebtTableLoading(false);
      });
  };

  const getBalanceSheetEquityTableData = () => {
    setBalanceSheetEquityTableLoading(true);
    getBalanceSheetEquityData({ ticker })
      .then((res) => {
        const origArr = res.data.array;
        const treatedArr = origArr.map((item: any, idx: number) => ({
          ...item,
          key: idx,
        }));
        setBalanceSheetEquityInfo(res.data.content);
        setBalanceSheetEquityTableData(treatedArr);
        setBalanceSheetEquityTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setBalanceSheetEquityTableLoading(false);
      });
  };

  useEffect(() => {
    getbalanceSheetFullData();
    getBalanceSheetTotalAssetTableData();
    getBalanceSheetCurrentAssetTableData();
    getBalanceSheetNonCurrentAssetTableData();
    getBalanceSheetCurrentDebtTableData();
    getBalanceSheetNonCurrentDebtTableData();
    getBalanceSheetEquityTableData();
  }, []);

  return (
    <>
      <div className="table-container">
        <BalanceSheetFullTable
          dataSource={balanceSheetFullData}
          loading={balanceSheetFullLoading}
        />
      </div>
      <div className="table-container">
        <BalanceSheetTotalAssetTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={balanceSheetTotalAssetInfo}
          dataSource={balanceSheetTotalAssetTableData}
          loading={balanceSheetTotalAssetTableLoading}
        />
      </div>
      <div className="table-container">
        <BalanceSheetCurrentAssetTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={balanceSheetCurrentAssetInfo}
          dataSource={balanceSheetCurrentAssetTableData}
          loading={balanceSheetCurrentAssetTableLoading}
        />
      </div>
      <div className="table-container">
        <BalanceSheetNonCurrentAssetTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={balanceSheetNonCurrentAssetInfo}
          dataSource={balanceSheetNonCurrentAssetTableData}
          loading={balanceSheetNonCurrentAssetTableLoading}
        />
      </div>
      <div className="table-container">
        <BalanceSheetCurrentDebtTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={balanceSheetCurrentDebtInfo}
          dataSource={balanceSheetCurrentDebtTableData}
          loading={balanceSheetCurrentDebtTableLoading}
        />
      </div>
      <div className="table-container">
        <BalanceSheetNonCurrentDebtTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={balanceSheetNonCurrentDebtInfo}
          dataSource={balanceSheetNonCurrentDebtTableData}
          loading={balanceSheetNonCurrentDebtTableLoading}
        />
      </div>
      <div className="table-container">
        <BalanceSheetEquityTable
          companyTitle={companyTitle}
          ticker={ticker}
          info={balanceSheetEquityInfo}
          dataSource={balanceSheetEquityTableData}
          loading={balanceSheetEquityTableLoading}
        />
      </div>
    </>
  );
};

export default BalanceSheet;
